<template>
	<div id="view-gallery">
		<CoolLightBox
      		:useZoomBar="true"
			:items="imagesForLightbox"
			:index="lightboxIndex"
			@close="lightboxIndex = null">
		</CoolLightBox>

		<img
			class="center-block img-responsive"
			v-for="(image, index) in images"
			:key="index" @click="lightboxIndex = index"
			:src="'https://beta.cloud.servonet.it/' + image.thumb">
	</div>
</template>

<script>
import axios from 'axios'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
	name: "Gallery",
	components: {
		CoolLightBox
	},
	data () {
		return {
			images: [],
			imagesForLightbox: [],
			lightboxIndex: null,
		}
	},
	mounted () {
		axios.get("https://beta.cloud.servonet.it/json/app_essenzacofani/gallery")
			.then(response => response.data)
			.then(response => {
				this.images = response

				this.imagesForLightbox = response.map(image => {
					return {
						src: "https://beta.cloud.servonet.it/" + image.filename,
						thumb: "https://beta.cloud.servonet.it/" + image.thumb,
					}
				})
			})
	}
}
</script>

<style>
#view-gallery {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	padding: 8px;
	gap: 8px;
}
#view-gallery > img {
	cursor: pointer;
}
</style>